import { BusinessAccessT } from '~/types'

export const BusinessCategory = [
    {
        key: 'CAT01',
        id: 'Minimarket & Sembako',
        en: 'Minimarket'
    },
    {
        key: 'CAT02',
        id: 'Pakaian, Bahan Pakaian & Aksesoris',
        en: 'Clothing & Accessories'
    },
    {
        key: 'CAT03',
        id: 'Kecantikan, Kesehatan & Perawatan',
        en: 'Beauty, Health & Care'
    },
    {
        key: 'CAT04',
        id: 'Elektronik, HP & Aksesoris HP',
        en: 'Electronics, Handphone & Accessories'
    },
    {
        key: 'CAT05',
        id: 'Makanan & Minuman',
        en: 'Food & Beverages'
    },
    {
        key: 'CAT06',
        id: 'Buah, Sayur, Daging & Makanan Mentah',
        en: 'Fresh Fruits, Vegetables, Meat & Raw Food'
    },
    {
        key: 'CAT07',
        id: 'Alat Kantor & Buku',
        en: 'Office Tools & Book'
    },
    {
        key: 'CAT08',
        id: 'Onderdil Otomotif',
        en: 'Automotive Parts'
    },
    {
        key: 'CAT09',
        id: 'Alat Material Rumah',
        en: 'Home Tools & Materials'
    },
    {
        key: 'CAT10',
        id: 'Pertanian, Peternakan & Perikanan',
        en: 'Agriculture, Farm & Fishery'
    },
    {
        key: 'CAT11',
        id: 'Perhiasan',
        en: 'Jewelry'
    },
    {
        key: 'CAT00',
        id: 'Lainnya',
        en: 'Others'
    }
]

export const allAccess: BusinessAccessT[] = [
    'stockIn',
    'stockOut',
    'stockAudit',
    'addProduct',
    'editProduct',
    'deleteProduct',
    'readCapitalPrice',
    'addCategory',
    'editCategory',
    'deleteCategory',
    'addStaff',
    'removeStaff',
    'removeStaff',
    'changeBusinessProfile',
    'addBusinessCustomer',
    'editBusinessCustomer',
    'removeBusinessCustomer',
    'addBusinessSupplier',
    'editBusinessSupplier',
    'removeBusinessSupplier',
    'readAccountingReport'
]
