import { useState } from 'react'

import { CreateBusiness, UpdateBusiness } from '~/api'
import { Button, Icon, ImageUploader, Modal, Select, TextField } from '~/components'
import { BusinessCategory } from '~/constants/business'
import $amplitude from '~/lib/amplitude'
import $posthog from '~/lib/posthog'
import locale from '~/locale'
import { BusinessI } from '~/types'
import { getCurrentBusiness } from '~/utils'
import { useGlobal } from '../Common/GlobalDataProvider'

interface PopupBusinessFormI {
    show: boolean
    data?: BusinessI
    onSave: (value: BusinessI) => void
    onClose: () => void
}

export const PopupBusinessForm = ({ show, data, onSave, onClose }: PopupBusinessFormI) => {
    const isEdit = !!data
    const { toastProps, setToastProps } = useGlobal()
    const [loading, setLoading] = useState(false)
    const [field, setField] = useState<BusinessI>(() => {
        if (isEdit) {
            return data
        }

        return {
            access: [],
            businessId: '',
            role: 'admin',
            category: '',
            imageUrl: '',
            name: '',
            currencyId: 'IDR'
        }
    })

    const submit = async () => {
        if (loading) {
            return
        }

        setLoading(true)

        const { data: business, error } = isEdit
            ? await UpdateBusiness(getCurrentBusiness(), {
                  category: field.category,
                  imageUrl: field.imageUrl,
                  name: field.name,
                  currencyId: field.currencyId || 'IDR'
              })
            : await CreateBusiness({
                  category: field.category,
                  imageUrl: field.imageUrl,
                  name: field.name,
                  currencyId: field.currencyId || 'IDR'
              })

        if (!!error) {
            setLoading(false)
            setToastProps({
                ...toastProps,
                message: error?.message || locale.id.common.request_error,
                type: 'error',
                open: true,
                wrapper: 'right-hand-side'
            })

            return
        }

        setToastProps({
            ...toastProps,
            message: locale.id.common.message.successfully_saved,
            type: 'success',
            open: true,
            wrapper: 'right-hand-side'
        })

        if (isEdit) {
            onSave(data)
            const eventProps = {
                business_name: field.name,
                business_category: field.category,
                business_logo: field.imageUrl.length ? 'yes' : 'no',
                business_id: data.businessId
            }
            $posthog.capture('business_create_submit', eventProps)
            $amplitude.track('business_create_submit', eventProps)
            return
        }

        const _business = business as BusinessI
        onSave(_business)
        const eventProps = {
            business_name: field.name,
            business_category: field.category,
            business_logo: field.imageUrl.length ? 'yes' : 'no',
            business_id: _business.businessId
        }
        $posthog.capture('business_create_submit', eventProps)
        $amplitude.track('business_create_submit', eventProps)
    }

    return (
        <Modal
            size="sm"
            show={show}
            title={isEdit ? locale.id.business.edit_business.title : locale.id.business.add_business.title}
            close
            onClose={onClose}
            action={
                <Button
                    text={locale.id.common.button.submit}
                    icon={<Icon name="save" size="lg"></Icon>}
                    loading={loading}
                    onClick={submit}></Button>
            }>
            <div className="grid grid-cols-1 gap-4 w-full max-w-sm mb-6">
                <ImageUploader
                    image={field.imageUrl}
                    setImage={(value) =>
                        setField({
                            ...field,
                            imageUrl: value
                        })
                    }></ImageUploader>
                <TextField
                    label={locale.id.business.add_business.name}
                    placeholder={locale.id.business.add_business.name_placeholder}
                    value={field.name}
                    onInput={(value) =>
                        setField({
                            ...field,
                            name: value
                        })
                    }></TextField>
                <Select
                    dropdownAnchor="top"
                    label={locale.id.business.add_business.category}
                    placeholder={locale.id.business.add_business.category_placeholder}
                    value={field.category}
                    dropdownMaxHeight={200}
                    options={BusinessCategory.map((item) => ({
                        text: item.id,
                        value: item.key
                    }))}
                    onSelect={(value) =>
                        setField({
                            ...field,
                            category: value
                        })
                    }></Select>
            </div>
        </Modal>
    )
}
